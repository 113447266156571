import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { Vocab } from "../model/Vocab";
import { VocabInputType } from "../type/VocabInputType";
import { VocabHeader } from "./VocabHeader";
import { VocabRow } from "./VocabRow";

type Props = {
  title: string;
  vocabs: Vocab[];
  inputType: VocabInputType;
};

export const VocabTable: FC<Props> = ({ title, vocabs, inputType }) => (
  <Box mb={5}>
    <Typography variant="h5">{title}</Typography>
    <VocabHeader mt={1} />

    {vocabs.map((vocab, index) => (
      <VocabRow key={index} vocab={vocab} inputType={inputType} />
    ))}
  </Box>
);
