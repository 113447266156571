import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import * as React from "react";
import { useState } from "react";
import { VocabTool } from "./feature/vocable/container/VocabTool";
import { VocabInputType } from "./feature/vocable/type/VocabInputType";

export default function App() {
  const [inputType, setInputType] = useState<VocabInputType>(
    VocabInputType.ENG
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <VocabTool />
      </Box>
    </Container>
  );
}
