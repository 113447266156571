import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { FC } from "react";
import { VocabInputType } from "../type/VocabInputType";

type Props = {
  inputType: VocabInputType;
  onInputType(type: VocabInputType): void;
};

export const VocabTypeSelector: FC<Props> = ({ inputType, onInputType }) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: string | null
  ) => {
    onInputType(
      newType ? (newType as unknown as VocabInputType) : VocabInputType.SWE
    );
  };

  return (
    <ToggleButtonGroup
      value={inputType}
      exclusive
      onChange={handleChange}
      aria-label="text alignment"
    >
      <ToggleButton
        color="primary"
        value={VocabInputType.SWE}
        aria-label="left aligned"
      >
        Till engelska
      </ToggleButton>
      <ToggleButton
        color="primary"
        value={VocabInputType.ENG}
        aria-label="left aligned"
      >
        Till svenska
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
