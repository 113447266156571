import { HelpOutline } from "@mui/icons-material";
import { ButtonProps, IconButton, Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";

type Props = ButtonProps & {
  icon: ReactNode;
};

export const InputIconButton: FC<Props> = ({ icon, ...props }) => (
  <IconButton tabIndex={-1} {...props}>
    {icon}
  </IconButton>
);
