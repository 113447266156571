const isMatch = (a: string | undefined, b: string | undefined): boolean =>
  a?.trim().toLowerCase() === b?.trim().toLowerCase();

const sliceMatch = (expected: string, actual: string): string => {
  const parts = expected.split("");
  const idx = parts.findIndex((c, idx) => c !== actual[idx]);
  const idxToUse = idx === -1 ? expected.length : idx + 1;
  return expected.slice(0, idxToUse);
};

export const StringUtil = {
  isMatch,
  sliceMatch,
};
