import { Grid, GridProps, Hidden } from "@mui/material";
import React, { FC } from "react";
import { TableHeader } from "../component/TableHeader";

type Props = GridProps;

export const VocabHeader: FC<Props> = ({ ...props }) => (
  <Grid container spacing={2} alignItems="center" mb={1} {...props}>
    <Grid item md={2} xs={4.5}>
      <TableHeader label="Översätt ord" />
    </Grid>
    <Grid item md={4.5} xs={7.5}>
      <TableHeader label="Översättning" />
    </Grid>
    <Hidden mdDown>
      <Grid item xs={5.5}>
        <TableHeader label="Valfri mening" />
      </Grid>
    </Hidden>
  </Grid>
);
