import { Homework } from "../model/Homework";

export const Week8: Homework = {
  title: "Week 8",
  vocabs: {
    sourceLangCode: "en",
    targetLangCode: "sv",
    vocabs: [
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "recover",
        targetWord: "ta igen sig",
        actualTargetLang: "sv",
        sentence: "I am recovering from my cold.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "brotherhood",
        targetWord: "broderskap",
        actualTargetLang: "sv",
        sentence: "The bond of brotherhood is strong.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "oppression",
        targetWord: "förtryck",
        actualTargetLang: "sv",
        sentence: "Oppression is an unjust or cruel exercise of power.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "transform",
        targetWord: "omvandla",
        actualTargetLang: "sv",
        sentence: "The butterfly transformed from a caterpillar.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "oasis",
        targetWord: "oas",
        actualTargetLang: "sv",
        sentence: "An oasis provides a cool refuge in the desert.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "judge",
        targetWord: "bedöma",
        actualTargetLang: "sv",
        sentence: "The judge ruled in favor of the plaintiff.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "content",
        targetWord: "innehåll",
        actualTargetLang: "sv",
        sentence: "The content of the book was interesting.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "characteris",
        targetWord: "karakteris",
        actualTargetLang: "sv",
        sentence: "Her characteris was one of kindness.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "occupy",
        targetWord: "uppta",
        actualTargetLang: "sv",
        sentence: "The protesters occupied the city square.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "starvation",
        targetWord: "svält",
        actualTargetLang: "sv",
        sentence: "Starvation is a serious problem that needs to be addressed.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "fulfill",
        targetWord: "uppfylla",
        actualTargetLang: "sv",
        sentence: "I will fulfill my promise.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "image",
        targetWord: "bild",
        actualTargetLang: "sv",
        sentence: "An image can tell a thousand words.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "destiny",
        targetWord: "Öde",
        actualTargetLang: "sv",
        sentence: "Destiny is what life is all about.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "prosperity",
        targetWord: "välstånd",
        actualTargetLang: "sv",
        sentence: "Prosperity brings joy and abundance.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "promote",
        targetWord: "främja",
        actualTargetLang: "sv",
        sentence: "Promote growth and success.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "cynicism",
        targetWord: "cynism",
        actualTargetLang: "sv",
        sentence: "Cynicism can lead to a pessimistic outlook on life.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "doubts",
        targetWord: "tvivel",
        actualTargetLang: "sv",
        sentence: "I have my doubts.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "unfold",
        targetWord: "veckla ut",
        actualTargetLang: "sv",
        sentence: "Unfold the mystery.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "brief",
        targetWord: "kort",
        actualTargetLang: "sv",
        sentence: "I gave a brief overview of the project.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "ashore",
        targetWord: "i land",
        actualTargetLang: "sv",
        sentence: "The boat docked ashore.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "pine tree",
        targetWord: "tall",
        actualTargetLang: "sv",
        sentence: "A tall pine tree towers over the landscape.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "moose",
        targetWord: "älg",
        actualTargetLang: "sv",
        sentence: "The moose crossed the road.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "boast",
        targetWord: "skryta",
        actualTargetLang: "sv",
        sentence: "He boasted about his success.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "garlic",
        targetWord: "vitlök",
        actualTargetLang: "sv",
        sentence: "Garlic adds flavor to many dishes.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "farmer",
        targetWord: "jordbrukare",
        actualTargetLang: "sv",
        sentence: "The farmer harvested the crop.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "variety",
        targetWord: "mängd",
        actualTargetLang: "sv",
        sentence: "A variety of options are available.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "admiration",
        targetWord: "beundran",
        actualTargetLang: "sv",
        sentence: "I have great admiration for her courage.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "recognition",
        targetWord: "erkännande",
        actualTargetLang: "sv",
        sentence: "Recognition is an important part of success.",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "affection",
        targetWord: "tillgivenhet",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "peak",
        targetWord: "topp",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "eventually",
        targetWord: "så småningom",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "magnificent",
        targetWord: "magnifik",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "ethics",
        targetWord: "etik",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "pride",
        targetWord: "stolthet",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "remarkable",
        targetWord: "anmärkningsvärd",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "resource",
        targetWord: "resurs",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "wheat",
        targetWord: "vete",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "bilingual",
        targetWord: "tvåspråkig",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "recognize",
        targetWord: "känna igen",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "cherish",
        targetWord: "vårda",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "American Dreams",
        targetWord: "Amerikanska drömmar",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "vast",
        targetWord: "omfattande",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "mountain range",
        targetWord: "bergskedja",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "tribe",
        targetWord: "stam",
        actualTargetLang: "sv",
      },
      {
        sourceLang: "en",
        targetLang: "sv",
        sourceWord: "crops",
        targetWord: "gröda",
        actualTargetLang: "sv",
      },
    ],
  },
};
