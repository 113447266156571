import { Delete, DeleteOutline } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FC, useState } from "react";
import { Week4 } from "../homework/Week4";
import { Week5 } from "../homework/Week5";
import { Week8 } from "../homework/Week8";
import { VocabInputType } from "../type/VocabInputType";
import { VocabTable } from "./VocabTable";
import { VocabTypeSelector } from "./VocabTypeSelector";

const HOME_WORK = [Week8, Week5, Week4];

export const VocabTool: FC = () => {
  const [inputType, setInputType] = useState<VocabInputType>(
    VocabInputType.SWE
  );

  const [show, setShow] = useState<boolean>(true);

  const onInputType = (type: VocabInputType): void => {
    setInputType(type);
    onClear();
  };

  const onClear = (): void => {
    setShow(false);
    setTimeout(() => setShow(true), 100);
  };

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Glosor
      </Typography>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        mt={2}
        mb={4}
      >
        <Grid item>
          <VocabTypeSelector inputType={inputType} onInputType={onInputType} />
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={onClear}
            startIcon={<DeleteOutline />}
          >
            Rensa
          </Button>
        </Grid>
      </Grid>

      {show &&
        HOME_WORK.map((homework) => (
          <VocabTable
            title={homework.title}
            inputType={inputType}
            vocabs={homework.vocabs.vocabs}
          />
        ))}
    </>
  );
};
