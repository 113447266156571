import {
  DeleteOutline,
  HelpOutline,
  SentimentSatisfiedAlt,
  Visibility,
} from "@mui/icons-material";
import {
  Grid,
  Hidden,
  Icon,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, MouseEventHandler, useRef, useState } from "react";
import { ClickEvent } from "../../../common/ui/ClickEvent";
import { FormEvent } from "../../../common/ui/FormEvent";
import { TouchEvent } from "../../../common/ui/TouchEvent";
import { InputIconButton } from "../component/InputIconButton";
import { Vocab } from "../model/Vocab";
import { VocabInputType } from "../type/VocabInputType";
import { StringUtil } from "../util/StringUtil";

type Props = {
  vocab: Vocab;
  inputType: VocabInputType;
};

export const VocabRow: FC<Props> = ({
  vocab: { sourceWord, targetWord, sentence = "" },
  inputType,
}) => {
  const [question, answer] =
    inputType === VocabInputType.ENG
      ? [sourceWord, targetWord]
      : [targetWord, sourceWord];
  const [result, setResult] = useState<boolean>(false);
  const [answerInput, setAnswerInput] = useState<string>("");
  const cacheRef = useRef<string>();
  const [sentenceInput, setSentenceInput] = useState<string>("");

  const handleAnswerHelp = (event: ClickEvent): void => {
    setAnswerInput(StringUtil.sliceMatch(answer, answerInput));
    event.preventDefault();
    event.stopPropagation();
  };

  const handleAnswerChange = (event: FormEvent) => {
    setAnswerInput(event.target.value);
    setResult(StringUtil.isMatch(event.target.value, answer));
  };

  const handleSentenceHelp = (event: ClickEvent): void => {
    setSentenceInput(sentence);
    event.preventDefault();
  };

  const handleSentenceClear = (event: ClickEvent): void => {
    setSentenceInput("");
    event.preventDefault();
  };

  const handleSentenceChange = (event: FormEvent) => {
    setSentenceInput(event.target.value);
  };

  const handleShowPeek = (event: ClickEvent | TouchEvent): void => {
    cacheRef.current = answerInput;
    setAnswerInput(answer);
    event.preventDefault();
  };

  const handleHidePeek = (event: ClickEvent | TouchEvent): void => {
    setAnswerInput(cacheRef.current ?? "");
    cacheRef.current = "";
    event.preventDefault();
  };

  return (
    <Grid container spacing={2} alignItems="center" mb={3}>
      <Grid item md={2} xs={4.5}>
        <Typography variant="body1" fontWeight={500} noWrap>
          {question}
        </Typography>
      </Grid>
      <Grid item md={4.5} xs={7.5}>
        <TextField
          fullWidth
          value={answerInput}
          onChange={handleAnswerChange}
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {result ? <SentimentSatisfiedAlt color="success" /> : <Icon />}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Hidden smDown>
                  <InputIconButton
                    icon={<HelpOutline />}
                    onClick={handleAnswerHelp}
                  />
                </Hidden>
                <InputIconButton
                  icon={<Visibility />}
                  onMouseDown={handleShowPeek}
                  onTouchStart={handleShowPeek}
                  onMouseUp={handleHidePeek}
                  onTouchEnd={handleHidePeek}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Hidden mdDown>
        <Grid item xs={5.5}>
          <TextField
            fullWidth
            value={sentenceInput}
            onChange={handleSentenceChange}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {sentenceInput.length ? (
                    <InputIconButton
                      icon={<DeleteOutline />}
                      onClick={handleSentenceClear}
                    />
                  ) : !!sentence?.trim().length ? (
                    <InputIconButton
                      icon={<HelpOutline />}
                      onClick={handleSentenceHelp}
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
};
