import { Typography } from "@mui/material";
import React, { FC } from "react";

type Props = {
  label: string;
};

export const TableHeader: FC<Props> = ({ label }) => (
  <Typography
    variant="caption"
    fontSize="1rem"
    color="grey"
    fontWeight={500}
    noWrap
  >
    {label}
  </Typography>
);
