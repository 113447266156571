import { Homework } from "../model/Homework";
import { Vocabs } from "../model/Vocabs";

export const Week5: Homework = {
  title: "Vecka 5",
  vocabs: {
    sourceLangCode: "en",
    targetLangCode: "sv",
    vocabs: [
      {
        sourceWord: "unfold",
        targetWord: "veckla ut",
        sentence: "She unfolded the map to get her bearings.",
      },
      {
        sourceWord: "brief",
        targetWord: "kortfattad",
        sentence: "I will keep my explanation brief.",
      },
      {
        sourceWord: "ashore",
        targetWord: "i land",
        sentence: "The boat finally arrived ashore after a long journey.",
      },
      {
        sourceWord: "sledgehammer",
        targetWord: "slägga",
        sentence: "He used a sledgehammer to break the wall.",
      },
      {
        sourceWord: "sparrow",
        targetWord: "sparv",
        sentence: "The sparrow chirped happily in the tree.",
      },
      {
        sourceWord: "starling",
        targetWord: "stare",
        sentence: "The starling flew gracefully across the sky.",
      },
      {
        sourceWord: "blacksmith",
        targetWord: "smed",
        sentence: "The blacksmith hammered the hot iron into shape.",
      },
      {
        sourceWord: "anvil",
        targetWord: "städ",
        sentence: "The blacksmith pounded on the anvil to shape the metal.",
      },
      {
        sourceWord: "steam",
        targetWord: "ånga",
        sentence:
          "The steam from the boiling pot filled the kitchen with a warm,",
      },
      {
        sourceWord: "domestic dog",
        targetWord: "tamhund",
        sentence: "Domestic dogs are popular pets.",
      },
      {
        sourceWord: "engine",
        targetWord: "motor",
        sentence: "The engine of the car was running smoothly.",
      },
      {
        sourceWord: "carrige",
        targetWord: "vagn",
        sentence: "The horse-drawn carrige took them to the ball.",
      },
      {
        sourceWord: "brake",
        targetWord: "broms",
        sentence: "Step on the brake to slow down the car.",
      },
      {
        sourceWord: "urgent",
        targetWord: "brådskane",
        sentence: "The urgent deadline requires immediate attention.",
      },
      {
        sourceWord: "tram",
        targetWord: "spårvagn",
        sentence: "I caught the tram to the city centre.",
      },
      {
        sourceWord: "port",
        targetWord: "hamn",
        sentence: "I'm docking the boat at the port.",
      },
      {
        sourceWord: "cutlery",
        targetWord: "bestick",
        sentence: "I need to buy some new cutlery for my kitchen.",
      },
      {
        sourceWord: "typewriter",
        targetWord: "skrivmaskin",
        sentence: "I typed my essay on an old-fashioned typewriter.",
      },
    ],
  },
};
