import { Homework } from "../model/Homework";

export const Week4: Homework = {
  title: "Vecka 4",

  vocabs: {
    sourceLangCode: "en",
    targetLangCode: "sv",
    vocabs: [
      {
        sourceWord: "dog",
        targetWord: "hund",
        sentence: "I love dogs",
      },
      {
        sourceWord: "cat",
        targetWord: "katt",
        sentence: "I'm a cat person",
      },
      {
        sourceWord: "vast",
        targetWord: "vidsträckt",
        sentence: "The vast ocean stretched out before us.",
      },
      {
        sourceWord: "mountain range",
        targetWord: "bergskedja",
        sentence: "The mountain range was a formidable obstacle to the hikers",
      },
      {
        sourceWord: "tribe",
        targetWord: "folkstam",
        sentence: "The tribe had lived in the jungle for generations",
      },
      {
        sourceWord: "crops",
        targetWord: "skörd, grödor",
        sentence: "The farmer tended to his crops carefully",
      },
      {
        sourceWord: "recover",
        targetWord: "återhämta",
        sentence: "She is recovering from her surgery",
      },
      {
        sourceWord: "brotherhood",
        targetWord: "brödraskap",
        sentence: "They formed a brotherhood of friends",
      },
      {
        sourceWord: "oppression",
        targetWord: "förtryck",
        sentence: "The caterpillar transformed into a butterfly",
      },
      {
        sourceWord: "transform",
        targetWord: "förändra",
        sentence: "",
      },
      {
        sourceWord: "oasis",
        targetWord: "oas",
        sentence: "The oasis was a welcome sight in the desert",
      },
      {
        sourceWord: "judge",
        targetWord: "döma",
        sentence: "The judge decided the case fairly",
      },
      {
        sourceWord: "content",
        targetWord: "innehåll",
        sentence: "",
      },
      {
        sourceWord: "character",
        targetWord: "personlighet",
        sentence: "He had a strong character, never giving up",
      },
      {
        sourceWord: "occupy",
        targetWord: "inta",
        sentence: "They decided to occupy the empty building",
      },
      {
        sourceWord: "starvation",
        targetWord: "svält",
        sentence: "Starvation is a serious problem in many parts of the world",
      },
      {
        sourceWord: "fulfill",
        targetWord: "fullgöra, tillfredsställa",
        sentence: "He fulfilled his promise to his family",
      },
      {
        sourceWord: "image",
        targetWord: "bild",
        sentence: "The image was captured by the camera",
      },
      {
        sourceWord: "destiny",
        targetWord: "öde",
        sentence: "",
      },
      {
        sourceWord: "prosperity",
        targetWord: "rikedom, förmögenhet",
        sentence: "",
      },
      {
        sourceWord: "promote",
        targetWord: "främja",
        sentence: "",
      },
      {
        sourceWord: "cynicism",
        targetWord: "cynism",
        sentence: "",
      },
      {
        sourceWord: "doubts",
        targetWord: "tvivel",
        sentence: "",
      },
    ],
  },
};
